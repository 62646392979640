<template>
    <component
        :is="comp"
        :tourDetails="tourDetails"
        @handlerGoClassPatrolClick_record="handlerGoClassPatrolClick_record"
        @classPatrolBack="classPatrolBack"
        @setPageInitTabs="setPageInitTabs"
    >
    </component>
</template>
<script>
    import TeachingResearchClassroomObservationRecordsView from './TeachingResearchClassroomPatrolRecord/index.vue';
    import ClassPatrolTemp from './TeachingResearchCourseScheduleTemp/ClassPatrolTemp.vue';
    import AddObservationRecordsView from './TeachingResearchClassroomPatrolRecord/AddObservationRecordsView.vue';
    export default {
        name: "TeachingResearchClassroomPatrolRecordsScrollWrapper",
        components: {
            TeachingResearchClassroomObservationRecordsView,
            ClassPatrolTemp, //原巡课详情页面
            AddObservationRecordsView,//0308修改添加巡课详情页面
        },
        data () {
            return {
                compTel: ['TeachingResearchClassroomObservationRecordsView', 'AddObservationRecordsView'],
                idx: 0,
                tourDetails: {}
            }
        },
        created () {
            this.init()
        },
        computed: {
            comp () {
                return this.compTel[this.idx];
            }
        },
        methods: {
            init () {
                this.checkPermissions();
            },
            checkPermissions () {
                let _this = this
                let param = this.$route.query;
                if (param.id && param.watchTitle) {
                    console.log('1-11')
                    setTimeout(() => {
                        _this.$eventBroadcast('goToDetail', param);
                    }, 0);
                }
            },
            /**
             * @Description: 操作列表进入巡课页面
             * @DoWhat: 操作列表进入巡课页面
             * @UseScenarios: 操作列表进入巡课页面
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-20 16:58:05
             */
            handlerGoClassPatrolClick_record (data) {
                console.log(data,'data')
                this.tourDetails = data;
                this.idx = '1';

            },
            /**
             * @Description:
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author:
             * @Date: 2022-11-20 20:37:21
             */
            classPatrolBack () {
                this.idx = '0';
            },
            setPageInitTabs() {
                this.$eventDispatch('setGlobalPageType', 'list');
                this.idx = 0;
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>
