<template>
    <div class="based-content">
      <div class="based-title">
          <span>基础信息</span>
          <div class="based-line"></div>
      </div>
      <div class="based-main">
          <div class="based-form">
              <el-form label-position="right" style="display: flex;flex-direction: column;justify-content: space-between;">
                  <el-row :gutter="20">
                      <el-col :span="6">
                          <el-form-item label="方案名称:">
                            <el-tooltip class="item" effect="dark" :content="list.programmeName" :disabled="list.programmeName && list.programmeName.length<12">
                              <span class="programme-name">{{ list.programmeName }}</span>
                            </el-tooltip>
                          </el-form-item>
                      </el-col>
                      <el-col :span="5">
                          <el-form-item label="巡课方式:">
                              <span class="targrt-name">{{ list.programmeType == '1'? '自由巡课' : '固定课程巡课' }}</span>
                          </el-form-item>
                      </el-col>
                      <el-col :span="6">
                          <el-form-item label="巡课对象:">
                            <el-tooltip class="item" effect="dark" :content="list.targetName" :disabled="list.targetName && list.targetName.length<12">
                              <span v-if="list.programmeType == '1'" class="targrt-name-freedom">{{ list.targetName }}</span>
                              <span v-if="list.programmeType == '2'" class="targrt-name-fixed">{{ list.targetName }}</span>
                            </el-tooltip>
                          </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <!-- targetType  1巡课对象班级   2巡课对象教师 -->
                          <el-form-item v-if="targetType=='1'" label="上课教师:">
                              <span style="margin-left:-5px">{{ list.classTeacherName }}</span>
                          </el-form-item>
                          <el-form-item v-else-if="targetType=='2'" label="上课班级:">
                              <span style="margin-left:-5px">{{ list.gradeName }} / {{ list.className }}</span>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <!-- 自由 -->
                  <el-row :gutter="20" v-if="list.programmeType == '1'">
                    <el-col :span="6">
                        <el-form-item label="科目:">
                        <span style="margin-left: -5px;">{{ list.subjectName }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="上课时间:">
                            <span  class="class-time">{{ list.classTime }}</span>
                        </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 固定 -->
                  <el-row :gutter="20" v-if="list.programmeType == '2'">
                    <el-col :span="6">
                        <el-form-item label="巡课班级:">
                            <span style="margin-left: -5px;">{{ list.gradeName }} / {{ list.className }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="科目:">
                            <span style="margin-left: 20px;">{{ list.subjectName }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="上课时间:">
                            <span  class="class-time">{{ list.classTime }}</span>
                        </el-form-item>
                    </el-col>
                  </el-row>
              </el-form>
              <div class="score" v-if="showScore">
                <div class="score-info-text">
                    <div class="score-info">{{score}}</div>
                    <div class="score-text">分</div>
                </div>
                <div class="score-line">
                    <img :src="lineImg" alt="">
                </div>
              </div>
          </div>
      </div>

      <div class="based-title">
          <span>巡课详情</span>
          <div class="based-line"></div>
      </div>
      <div class="detail-main">
          <div class="behavior-div">
              <!-- 教师行为 -->
              <div v-if="list.map.hasOwnProperty('1')">
                  <div class="behavior-title">
                      <span class="title-line"></span>
                      <span class="title-info">教师行为</span>
                  </div>
                  <div v-for="(item,index) in keyArrTeacher" :key="index" class="behavior-content">
                      <div class="behavior-content-title">{{index+1}} . {{list.map[1][item][0].contentName}}</div>
                      <div>
                          <div class="detail-div" v-if="list.map[1][item][0].type == '1' || list.map[1][item][0].type == null">
                              <el-radio-group v-for="(subItem,idx) in contentList[1][item]" v-model="infoTeacher[item].contentSetId" :key="idx" class="radioDiv">
                                  <el-radio disabled :label="subItem.setId">
                                    <span>{{ subItem.setName }}</span>
                                    <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                </el-radio>
                              </el-radio-group>
                          </div>
                          <div class="detail-div" v-else-if="list.map[1][item][0].type == '2'">
                            <el-checkbox-group v-model="infoTeacher[item].contentSetId" v-for="(subItem,idx) in list.map[1][item]" :key="idx" class="radioDiv">
                                <el-checkbox disabled :label="subItem.setId">
                                    <span>{{ subItem.setName }}</span>
                                    <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                </el-checkbox>
                            </el-checkbox-group>
                          </div>
                          <div v-else-if="contentList[1][item][0].type == '3'">
                            <div style="display:flex; justify-content: flex-start;margin-bottom:5px;margin-left: 23px;">
                                <span v-show="contentList[1][item][0].lowScore || contentList[1][item][0].lowScore == 0">
                                    <el-input-number disabled v-model="infoTeacher[item].contentSetScore" controls-position="right" :min="contentList[1][item][0].lowScore" :max="contentList[1][item][0].highScore"></el-input-number>
                                    <span class="remark-info">({{ contentList[1][item][0].lowScore }} - {{ contentList[1][item][0].highScore }} 分)</span>
                                </span>
                            </div>
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                maxlength="100"
                                show-word-limit
                                :disabled="true"
                                style="width: 920px;margin-left: 23px;"
                                v-model="infoTeacher[item].contentSetInfo">
                            </el-input>
                        </div>
                      </div>

                  </div>
              </div>
              <!-- 学生行为 -->
              <div v-if="list.map.hasOwnProperty('2')" class="behavior-line"></div>
              <div v-if="list.map.hasOwnProperty('2')">
                  <div class="behavior-title">
                      <span class="title-line"></span>
                      <span class="title-info">学生行为</span>
                  </div>
                  <div v-for="(item,index) in keyArrStu" :key="index" class="behavior-content">
                      <div class="behavior-content-title">{{index+1}} . {{list.map[2][item][0].contentName}}</div>
                      <div>
                          <div class="detail-div" v-if="list.map[2][item][0].type == '1'">
                              <el-radio-group v-for="(subItem,idx) in contentList[2][item]" v-model="infoStudent[item].contentSetId" :key="idx" class="radioDiv">
                                <el-radio disabled :label="subItem.setId">
                                    <span>{{ subItem.setName }}</span>
                                    <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                </el-radio>
                              </el-radio-group>
                          </div>
                          <div class="detail-div" v-else-if="list.map[2][item][0].type == '2'">
                            <el-checkbox-group v-model="infoStudent[item].contentSetId" v-for="(subItem,idx) in list.map[2][item]" :key="idx" class="radioDiv">
                                <el-checkbox disabled :label="subItem.setId">
                                    <span>{{ subItem.setName }}</span>
                                    <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                </el-checkbox>
                            </el-checkbox-group>
                          </div>
                          <div v-else-if="contentList[2][item][0].type == '3'">
                            <div style="display:flex; justify-content: flex-start;margin-bottom:5px;margin-left: 23px;">
                                <span v-show="contentList[2][item][0].lowScore || contentList[2][item][0].lowScore==0">
                                    <el-input-number disabled v-model="infoStudent[item].contentSetScore" controls-position="right" :min="contentList[2][item][0].lowScore" :max="contentList[2][item][0].highScore"></el-input-number>
                                    <span class="remark-info">({{ contentList[2][item][0].lowScore }} - {{ contentList[2][item][0].highScore }} 分)</span>
                                </span>
                            </div>
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                maxlength="100"
                                :disabled="true"
                                show-word-limit
                                style="width: 920px;margin-left: 23px;"
                                v-model="infoStudent[item].contentSetInfo">
                            </el-input>
                        </div>
                      </div>

                  </div>
              </div>
              <!-- 备注说明 -->
              <div v-if="remarkList" class="behavior-line"></div>
              <div v-if="remarkList">
                    <div class="behavior-title">
                        <span class="title-line"></span>
                        <span class="title-info">备注说明</span>
                    </div>
                    <div style="text-align:left">
                        <el-input
                            type="textarea"
                            :rows="5"
                            placeholder="请填写备注说明"
                            maxlength="500"
                            show-word-limit
                            disabled
                            style="width: 920px;margin-left: 23px;"
                            v-model="remarkList.contentSetInfo">
                        </el-input>
                    </div>
              </div>
          </div>
      </div>
    </div>
  </template>

  <script>
  import { classScheduleModel } from '@/models/ClassSchedule.js';
  import { mapState } from 'vuex';
  export default {
      data(){
          return{
              value1:'',
              score:'',
              basedFormData:{
                  schoolId:'',
                  programmeId:'',//方案id
                  programmeName:'',//方案名称
                  programmeType:'',//方案类型
                  targetId:'',//巡课对象id
                  targetName:'',//巡课对象名称
                  classId:'',//巡课班级id
                  className:'',//巡课班级名称
                  classTeacherId:'',//上课教师id
                  classTeacherName:'',//上课教师名称
                  classTime:'',//上课时间
                  subjectId:'',//巡课科目id
                  subjectName:'',//巡课科目名称
                  gradeId:'',//年级id
                  gradeName:'',//年级名称
                  infoList:[]
              },
              scheduleList:[],//巡课方案列表
              subjectList:[],//科目列表
              startTime:'',//上课时间起始时间
              endTime:'',//上课时间截止时间
              patrolTargetDialogObj:{
                  title: '选择巡课对象',
                  dialogVisible: false,
                  width: '600px',
              },
              patrolTargetForm:{},
            //   list:[],
              targetOptions:[],//固定巡课巡课对象弹框班级下拉框
              targetObj:{
                  class:'',
                  teacher:''
              },
              currentTarget:null,//被选中的巡课对象
              targetType:null,//自由巡课巡课对象1班级2教师
              contentList:[], //详情列表
              keyArrTeacher:[],//教师详情问题Id数组
              infoTeacher: {},//保存的内容列表
              keyArrStu:[],//学生详情问题Id数组
              infoStudent: {},//保存的内容列表
              teacherList:[],//上课教师列表
              radio:'',
              gradeList:[],
              gradeObj:{   //自由巡课对象班级下拉列表
                  key: 'classIds',
                  value: [],
                  organType: "",
                  list: [],
                  cascaderProps: {
                      label: "name",
                      multiple: false,
                      value: "id",
                      emitPath: false
                  },
                  filter(data) {
                      return data.organType !== '1';
                  },
              },
              remarkList:null,
              list:{},
              showScore:false
          }
      },
      props:{
        recordId:String
      },
      computed: {
          ...mapState({
              schoolId: state => state.schoolId
          }),
          emptyImg(){
              return require('@/assets/images/empty-patrol.png')
          },
          lineImg(){
            return require('@/assets/images/score-line.png')
          }
      },
      created(){
        //   this.getSubjectList()
        //   this.getTeacherList()
        //   this.getProgrammeList()
        this.getDetailsById()
      },
      methods:{
          /**
           * @Description: 获取记录详情
           * @Author: mrz
           * @Date: 2024-08-27 19:18:34
           */
           getDetailsById(){
            this.keyArrTeacher = []
            this.keyArrStu = []
            const classscheduleModel = new classScheduleModel();
            classscheduleModel.getRecordDetailsById({id:this.recordId}).then(res => {
                console.log('巡课记录详情：',res)
                this.targetType = res.data.data.targetType
                this.showScore = res.data.data.showScore
                //console.log('巡课记录详情：this.targetType',this.targetType)
                this.list = res.data.data
                this.score = res.data.data.score //总得分
                this.contentList = res.data.data.map
                this.remarkList = this.list.infoList.find(item => item.contentId == 'beizhushuoming')
                // this.list.map[1]----教师行为下的题
                const obj = {}

                //排序---教师行为新增部分
                if(this.contentList.hasOwnProperty('1')){
                    for(let key in this.contentList[1]){
                        if(this.contentList[1].hasOwnProperty(key)){
                            console.log('教师排序--详情：',this.contentList[1][key][0]['conSort'])
                            let a = this.contentList[1][key][0]['conSort'] - 1 //顺序字段
                            this.keyArrTeacher[a] = key
                        }
                    }
                }else{
                    this.keyArrTeacher = []
                }

                //this.keyArrTeacher = this.contentList.hasOwnProperty('1') ? Object.keys(this.contentList[1]) : []
                this.keyArrTeacher.forEach(item => {
                    let type = this.contentList[1][item][0]['type'];
                    let newArr = this.list.infoList.find(ele => ele.contentId == item)
                    if(newArr){
                        if(type == '2'){
                            //多选答案为字符串转数组
                            newArr['contentSetId'] = newArr['contentSetId'].split(',')
                        }
                        obj[item] = JSON.parse(JSON.stringify(newArr))
                    }else{
                        obj[item] = {
                            contentId:item,
                            contentSetId:'',
                            contentSetScore:'',
                            contentSetInfo:''
                        }
                        //obj[item]['contentSetId'] = type == '2' ? [] : '';
                        if(type == '2'){
                            //多选答案为字符串转数组
                            this.obj[item]['contentSetId'] = this.obj[item]['contentSetId'].split(',')
                        }
                    }
                })
                this.infoTeacher = JSON.parse(JSON.stringify(obj))
                console.log("教师行为下被选中的选项：",this.infoTeacher)
                // 学生
                const objStu = {}

                // 排序--新增部分
                if(this.contentList.hasOwnProperty('2')){
                    for(let key in this.contentList[2]){
                        if(this.contentList[2].hasOwnProperty(key)){
                            let a = this.contentList[2][key][0]['conSort'] - 1 //顺序字段
                            this.keyArrStu[a] = key
                        }
                    }
                }else{
                        this.keyArrStu = []
                }

                //this.keyArrStu =  this.contentList.hasOwnProperty('2') ? Object.keys(this.contentList[2]) : []
                this.keyArrStu.forEach(item => {
                    let type = this.contentList[2][item][0]['type'];
                    let stuArr = this.list.infoList.find(ele => ele.contentId == item)
                    if(stuArr){
                        if(type == '2'){
                            //多选答案为字符串转数组
                            stuArr['contentSetId'] = stuArr['contentSetId'].split(',')
                        }
                        objStu[item] = JSON.parse(JSON.stringify(stuArr))
                    }else{
                        objStu[item] = {
                            contentId:item,
                            contentSetId:'',
                            contentSetScore:'',
                            contentSetInfo:''
                        }
                        objStu[item]['contentSetId'] = type == '2' ? [] : '';
                        if(type == '2'){
                            //多选答案为字符串转数组
                            this.objStu[item]['contentSetId'] = this.objStu[item]['contentSetId'].split(',')
                        }
                    }
                })
                this.infoStudent = JSON.parse(JSON.stringify(objStu))

            })
           }
      }
  }
  </script>

  <style lang="scss" scoped>
  .based-content{
      width: 100%;
      height: calc(100vh - 171px);
      overflow-y: auto;
      .based-title{
          box-sizing: border-box;
          padding: 16px 0 12px 19px;
          border-bottom: 1px solid #EDEFF2;
          position: relative;
          .based-line{
              position: absolute;
              left: 23px;
              bottom: -1px;
              width: 56px;
              height: 3px;
              background: #3C7FFF;
              border-radius: 2px;
          }
          span{
              display: flex;
              justify-content: flex-start;
              height: 16px;
              line-height: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              font-size: 16px;
              color: #2B2F33;
          }
      }
      .based-main{
          padding: 6px 10px;
          .based-form{
              box-sizing: border-box;
              height: 94px;
              background: #F5F9FD;
              border-radius: 4px;
              padding:10px;
              position: relative;
              .programme-name{
                margin-left: -5px;
                max-width: 150px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .targrt-name-freedom{
                display: inline-block;
                margin-left: -5px;
                max-width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .targrt-name-fixed{
                display: inline-block;
                margin-left: -5px;
                max-width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .targrt-name{
                display: inline-block;
                margin-left:20px;
                max-width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .class-time{
                display: inline-block;
                width: 240px;
                margin-left: 17px;
              }
              span{
                display: inline-block;
                min-width: 150px;
                text-align: left;
              }
              .score{
                position: absolute;
                top: 15px;
                right: -35px;
                transform: translateX(-50%);
                width: 100px;
                .score-info-text{
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    height: 40px;
                    transform: translateY(6px);
                    transform: rotate(-16deg);
                    .score-info{
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        line-height: 40px;
                        font-size: 40px;
                        color: #E60000;
                        margin-right:5px;
                    }
                    .score-text{
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 16px;
                        color: #E60000;
                    }
                }
                .score-line{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                }
              }
          }
          ::v-deep .el-form-item__label{
              width: 57px;
              height: 14px;
              font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #888888;
              line-height: 32px;
              padding: 0;
          }
          ::v-deep .el-row{
              height: 42px;
          }
      }
      .detail-main{
          .empty-div{
              height: calc(100vh - 431px);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .empty-info{
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 14px;
                  color: #999999;
              }
          }
          .behavior-div{
            margin-bottom: 30px;
              padding:0 22px;
              box-sizing: border-box;
              .behavior-line{
                  height: 0;
                  width: 100%;
                  border-bottom: 1px dashed #E1E3E6;
              }
              .behavior-title{
                  height: 48px;
                  padding: 16px 0;
                  box-sizing: border-box;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .title-line{
                      display: inline-block;
                      width: 4px;
                      height: 15px;
                      background: #3C7FFF;
                      border-radius: 1px;
                      margin-right: 7px;
                  }
                  .title-info{
                      font-family: Microsoft YaHei;
                      font-weight: bold;
                      font-size: 16px;
                      color: #2B2F33;
                  }
              }
              .behavior-content{
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  margin-bottom: 30px;
                .behavior-content-title{
                    margin-bottom: 5px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #2B2F33;
                }
              }
          }
      }
  }
  .patrol-target-search{
      ::v-deep .el-form-item--small.el-form-item{
          margin-bottom: 13px !important;
      }
      ::v-deep .el-input--small .el-input__inner{
          background-color: #F5F5F5;
      }
      .patrol-target-line{
          width: 100%;
          height: 0;
          border-bottom: 1px dashed #D8D8D8;
      }
  }
  .patrol-target-timetable{
      margin-top: 23px;
  }
  ::v-deep .el-dialog__body{
      margin: 0 !important;
      padding: 17px 40px 0px 31px !important;
  }
  ::v-deep .el-cascader__tags{
      left: 6px;
  }
  ::v-deep .el-cascader-menu{
      min-width: 140px;
  }
  ::v-deep .el-checkbox__input.is-disabled.is-checked + .el-checkbox__label {
    color: #3C7FFF !important;
    }
    ::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #3C7FFF !important;
    border-color: #3C7FFF !important;
    }
    ::v-deep .el-radio__input.is-disabled.is-checked + .el-radio__label {
    color: #3C7FFF !important;
    }
    ::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner {
    background-color: #3C7FFF !important;
    border-color: #3C7FFF !important;
    }
    .remark-info{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-left: 16px;
    }
    ::v-deep  .el-textarea.is-disabled .el-textarea__inner{
        color: #333;
    }
    ::v-deep .el-textarea.is-disabled .el-textarea__inner{
        color:#333
    }
    ::v-deep .el-input.is-disabled .el-input__inner{
        color:#333
    }
    .detail-div{
        display: flex;
        flex-wrap: wrap;
        .radioDiv{
            margin: 10px 38px 10px 0;
            text-align: left;
            min-width: 444px;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
    }
  </style>
