<template>
  <div>
    <div class="timetable-title">{{ handleDate(data.scheduleTime) }}</div>
    <div class="timetable-main">
        <div v-for="(item,index) in data.child" :key="index" @mouseenter="timetableEnter(item.id)" @mouseleave="timetableLeave(item.id)" @click="handleClick(item)">
            <time-table-detail :childData="item"></time-table-detail>
        </div>
    </div>
  </div>
</template>

<script>
import TimeTableDetail from "./TimeTableDetail.vue"
export default {
    components:{
        TimeTableDetail
    },
    props:{
        data:Object
    },
    data(){
        return{}
    },
    methods:{
        /**
         * @Description:处理日期
         * @Author: mrz
         * @Date: 2024-08-26 10:25:53
         */
         handleDate(val){
            const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            const date = new Date(val)
            return days[date.getDay()]  + ' (' + val + ')'

         },
        /**
         * @Description: 鼠标移入事件
         * @Author: mrz
         * @Date: 2024-08-25 10:43:22
         */
         timetableEnter(id){
            const currentEl = document.getElementById(`detail_${id}`)
            // currentEl.style.border = '1px solid #E9E9EB'
            // currentEl.style.backgroundColor = '#DFEAFF'

            if(currentEl.className.includes('currentItem')){
                currentEl.style.border = '1px solid #3C7FFF'
                currentEl.style.backgroundColor = '#E8F0FF'
			}else{
                currentEl.style.border = '1px solid #E9E9EB'
                currentEl.style.backgroundColor = '#E8F0FF'
			}
         },
        /**
         * @Description: 鼠标移出事件
         * @Author: mrz
         * @Date: 2024-08-25 11:15:30
         */
         timetableLeave(id){
            const currentEl = document.getElementById(`detail_${id}`)
			if(currentEl.className.includes('currentItem')){
                currentEl.style.border = '1px solid #3C7FFF'
			}else{
                currentEl.style.border = '1px solid #F4F4F5'
                currentEl.style.backgroundColor = '#F4F4F5'
			}
         },
        /**
         * @Description: 选中巡课对象
         * @Author: mrz
         * @Date: 2024-08-25 11:41:26
         */
         handleClick(val){
            this.$emit("selectTarget",val)
            console.log('选中巡课对象',val)
         }
    }
}
</script>

<style lang="scss" scoped>
.timetable-title{
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 14px;
    color: #2B2F33;
    margin-top: 23px;
    margin-bottom: 14px;
    height: 14px;
    line-height: 14px;
}
.timetable-main{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    div{
        margin-right: 10px;
    }
}
</style>
